<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <card>
          <div class="row">
            <div class="form-group col-3">
              <label for>Location</label>
              <fg-input
                name="location"
                v-model="model.location"
                v-validate="modelValidations.location"
                :error="getError('location')"
              >
                <el-select
                  name="location"
                  :class="!getError('location') ? 'select-default-special' : 'select-danger'"
                  class="select-default-special"
                  placeholder="Select Location"
                  v-model="model.location"
                  filterable
                >
                  <el-option :value="null" class="select-success" label="All Locations"></el-option>
                  <el-option
                    v-for="(option, index) in locations"
                    class="select-success"
                    :value="option.id"
                    :label="`${option.location_id} - ${option.name}`"
                    :key="index"
                  ></el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="form-group col-3">
              <label>Choose Period</label>
              <div>
                <p-button v-for="(button, index) in buttons" :key="index" type="default" size="sm" simple @click="changeInterval(button.value)" >{{ button.label }}</p-button>
              </div>
            </div>
            <div class="form-group col-3">
              <label>From Date</label>
              <fg-input
                name="fromdate"
                type="date"
                v-validate="modelValidations.fromdate"
                v-model="model.fromdate"
                :error="getError('fromdate')"
              >
                <el-date-picker
                  name="fromdate"
                  v-model="model.fromdate"
                  type="date"
                  placeholder="Pick a From Date"
                ></el-date-picker>
              </fg-input>
            </div>
            <div class="form-group col-3">
              <label>End Date</label>
              <fg-input
                name="todate"
                type="date"
                v-validate="modelValidations.todate"
                v-model="model.todate"
                :error="getError('todate')"
              >
                <el-date-picker
                  name="todate"
                  v-model="model.todate"
                  type="date"
                  placeholder="Pick a End Date"
                ></el-date-picker>
              </fg-input>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="row">

      <div class="col-lg-2" v-for="(report, index) in reports" :key="index">
        <card type="pricing">
          <h6 class="card-category">{{ report.title }}</h6>
          <div class="card-icon icon-danger">
            <i :class="report.icon"></i>
          </div>
          <p class="muted text-center mt-3">{{ report.desc }}</p>
          <p-button type="default" round @click="download(report.slug)">Download</p-button>
        </card>
      </div>

    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { Card } from "src/components/UIComponents";
import { DatePicker, Select, Option } from "element-ui";
import { mapGetters } from "vuex";

import swal from 'sweetalert2'

Vue.use(Select);
Vue.use(Option);

export default {
  components: {
    Card,
    [DatePicker.name]: DatePicker
  },
  data() {
    return {
      model: {
        fromdate: null,
        todate: null,
        isAll: false,
        location: null
      },
      modelValidations: {
        fromdate: {
          required: true
        },
        todate: {
          required: true
        }
      },
      buttons: [
        {
          value: 6,
          label: '6 Months'
        },
        {
          value: 3,
          label: '3 Months'
        },
        {
          value: 1,
          label: '1 Month'
        },
        {
          value: 0,
          label: 'All'
        }
      ],
      reports: [
        {
          title: 'Sales Report',
          icon: 'fa fa-credit-card',
          desc: '',
          slug: 'sales'
        },
        {
          title: 'Payments Report',
          icon: 'fa fa-credit-card',
          desc: '',
          slug: 'payments'
        },
        {
          title: 'Inventory Report',
          icon: 'fa fa-store-alt',
          desc: '',
          slug: 'productpivot'
        },
        {
          title: 'Employees Report',
          icon: 'fa fa-user-friends',
          desc: '',
          slug: 'employees'
        },
        {
          title: 'Patients Report',
          icon: 'fa fa-id-card',
          desc: '',
          slug: 'patients'
        },
        {
          title: 'Leads Report',
          icon: 'fa fa-id-card',
          desc: '',
          slug: 'leads'
        },
        {
          title: 'Doctors Report',
          icon: 'fa fa-user-md',
          desc: '',
          slug: 'doctors'
        },
        {
          title: 'Events Report',
          icon: 'fa fa-calendar-alt',
          desc: '',
          slug: 'events'
        },
        {
          title: 'Appointments Report',
          icon: 'fa fa-calendar',
          desc: '',
          slug: 'appointments'
        },
        {
          title: 'Log Activity Report',
          icon: 'fa fa-list',
          desc: '',
          slug: 'activity'
        },
        {
          title: 'Consultation Report',
          icon: 'fa fa-user-md',
          desc: '',
          slug: 'consultation'
        },
        {
          title: 'Product Unit Sales Report',
          icon: 'fa fa-shopping-cart',
          desc: '',
          slug: 'product-sales'
        },
      ]
    };
  },
  mounted() {
    this.$store.dispatch("fetchLocationsAll");
  },
  computed: {
    ...mapGetters({
      locations: "getLocations",
    }),
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    changeInterval(months) {
      if(months != 0){
        this.model.todate = new Date();
        let date = new Date();
        date.setMonth(date.getMonth() - months);
        this.model.fromdate = date;
        this.model.isAll = false;
      }
      else{
        this.model.fromdate = new Date();
        this.model.todate = new Date();
        this.model.isAll = true;
      }
      
    },
    download(report) {
      this.$validator.validateAll().then(isValid => {
        this.$emit("on-submit", this.registerForm, isValid);
        if (isValid) {
          let authToken = JSON.parse(localStorage.getItem("zyro_user_token")).access_token;
          let headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`
          };

          this.$store.commit('setLoader', true);
          axios({
            url: `${process.env.VUE_APP_API_URL}/auth/report-${report}`,
            method: 'post',
            headers: headers,
            data: {
              fromdate: this.model.fromdate,
              todate: this.model.todate,
              isAll: this.model.isAll,
              location: this.model.location
            },
            responseType: "blob" // important
          }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${report}.xlsx`);
            document.body.appendChild(link);
            link.click();
            this.$store.commit('setLoader', false);
          });
        }
      });
    }
  }
};
</script>

<style>
</style>
